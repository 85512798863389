import './App.scss';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { lazy, Suspense, useLayoutEffect } from 'react';

const Admin = lazy(() => import('./layout/Admin'));
const Results = lazy(() => import('./layout/Result Page/Results'));
const NotFound = lazy(() => import('./components/NotFound'));
const Navbars = lazy(() => import('./components/Navbar'));
const Page = lazy(() => import('./Pages/Page'));
const SlugGuard = lazy(() => import('./libs/AvoidSlug'));

function App() {
  const { pathname } = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

 

  return (
    <Suspense fallback={<div/>}>
      <Navbars/>
      <Routes>
        <Route path='/admin' element={<Admin />} />
        <Route path="/" element={<Page />} />
        <Route path="/home" element={<Navigate to="/" replace />} />
        <Route path='/:uri' element={<SlugGuard />} />
        <Route path='/results/:uri' element={<Results />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}

export default App;
